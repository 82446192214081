#summary {
  position: relative;
  .filter{
    position: -webkit-sticky;
  position: sticky;
  top: 13rem;
  left: 0;
z-index: 10;
  }
  .summary_disabled {
    background-color: var(--Colours-Neutral-colours-Gray-100) !important;
    border-color: var(--Colours-Typography-colours-Disabled) !important;
    cursor: pointer !important;
    color: var( --Colours-Typography-colours-Disabled);
  }
  .accodion-card {
    border-radius: 0.125rem;
    background: var(--box-background-color);

    box-shadow: var(--box-shadow-color);
    min-height: 6rem;
    h2 {
      color: var(--Colours-Typography-colours-Default---800) !important;
      font-size: 1.5rem !important;
      font-family: "Medium";
      font-weight: 600 !important;
      margin-bottom: 0 !important;
    
    }
  }
  .summary-subcategory-body {
    margin: 0 20px;
    h2 {
      font-weight: 400 !important;
      font-size: 1.25rem !important;
      font-family: "Small";
      color: var(--Colours-Typography-colours-Default---800) !important;
      margin-bottom: 0 !important;
        width: 80%;
      word-wrap: break-word;
    }
  }
  .summary-subcategory-body:last-of-type {
    margin-bottom: 20px;
  }
  .previous-next-btn .primary-btn {
    display: none;
  }
  .span {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--Colours-Primary-colour-Blue-500);
    font-family: "Medium";
    font-weight: 500;
    text-decoration: underline;
    margin-left: 2px;
  }
  .active1 {
    border-radius: 0.125rem;
    background: var(--box-background-color);

    box-shadow: var(--box-shadow-color);
  }
  .answer-body{
    padding: 1rem !important;
  }
  .accordion-item {
    .accordion-card-img{
      P{
        color: var(--Colours-Neutral-colours-Gray-300);
      font-size: 0.75rem;
      font-weight: 500;
      margin-bottom: 0 !important;
      }
    }
    
  }
  .summaryMandtorycard{
    .accodion-card{
      height: 8.25rem !important;
    }
  }
 
  @media (min-width: 375px) and (max-width: 667px) {
    .accodion-card{
      h2{
        font-size: 1rem !important;
       
      }
    }
    .summary-subcategory-body{
      h2{
        font-size: 1rem !important;
        width: 60%;
        word-wrap: break-word;
      }
    }
    .filter{
      top:0 !important;
      left: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .summary-subcategory-body{
      h2{
        font-size: 1rem !important;
        width: 60%;
        word-wrap: break-word;
      }
    }
  }
}


