#CaFileUpload{
    .border-height{
        height: 18rem;
        max-height: 18rem;
        overflow-y: auto;
    }
    .uploadFile{
        height: 40rem;
        max-height: 40rem;
        overflow-y: auto; 
    }
}