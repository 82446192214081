/* PasswordInput.scss */

.custom-input {
  width: 100%;
  padding: 0.75rem 1.5rem 0.75rem 0.75rem !important;
  border: 1px solid var(--Colours-Primary-colour-Blue-100) !important;
  border-radius: var(--box-border-radius) !important;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);

  color: var(--Colours-Typography-colours-Default---800) !important;
  font-size: 0.875rem !important;
  font-family: "Medium-Regular" !important;
}

.custom-icon {
  position: absolute;
  top: 50%;
  right: 1.4rem;
  transform: translateY(-61%);
  cursor: pointer;
  font-size: 20px;
  color: black;
}
