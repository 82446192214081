// .login{
//     .force-popup{
//         position: fixed;
//       z-index: 1000;
//       left: 0;
//       top: 0;
//       width: 100%;
//       height: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       background-color: rgba(25, 37, 68, 0.7);
//     }
//     .Popup {
//         border-radius: var(--box-border-radius);
//         background-color: var(--Colours-Neutral-colours-White-10);
//         padding: 2.5rem 2rem;
//         width: 30rem;
//       }
//       h3 {
//         font-weight: 600;
//         font-size: 2rem;
//       }
//       h6{
//         font-size: 1.2rem;
//         color: var(  --Colours-System-colours-Alert-500);
//         font-family: "Large";
//       }

// }
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
 background-color:var(--Colours-Neutral-colours-Gray-100);
  /* Set the height of the container to 100% of the viewport height */
  .force-popup{
            position: fixed;
          z-index: 1000;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(25, 37, 68, 0.7);
        }
        .Popup {
            border-radius: var(--box-border-radius);
            background-color: var(--Colours-Neutral-colours-White-10);
            padding: 2.5rem 2rem;
            width: 30rem;
          }
          h3 {
            font-weight: 600;
            font-size: 2rem;
          }
          h6{
            font-size: 1.2rem;
            color: var(  --Colours-System-colours-Alert-500);
            font-family: "Large";
          }
    
}

.login-container {
  max-width: 400px; /* Adjust as needed */
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
