.sidebar {
  background-color: var(--Colours-Primary-colour-Blue-500);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 5rem;
  left: 0px;
  bottom: 0px;
  width: 8.333333%;
  padding: 2rem 0.75rem;

  .sidebar-section {
    flex-grow: 0.7;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .active-link {
    background-color: var(--Colours-Primary-colour-Blue-700);
    border-left: 0.375rem solid #fea834;
  }
  .sidebar-page {
    display: flex;
    width: 100%;
    height: 5.25rem;
    padding: 0.75rem 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 0.3rem;
    text-decoration: none;
    color: var(--Colours-Neutral-colours-White-10);

    p {
      font-family: "Medium-Regular";
      font-weight: 400;
      font-size: 0.875rem;
      color: var(--Colours-Neutral-colours-White-10);
    }
  }

  @media (min-width: 375px) and (max-width: 667px) {
    width: 25.333333%;
    z-index: 13;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    
    .sidebar {
      width: 13.333333%;
    }
    .sidebar-page p {
      font-size: 0.675rem;
    }

    .sidebar .sidebar-page {
      padding: 0.65rem 1.5rem;
    }

    .active-link {
      background-color: var(--Colours-Primary-colour-Blue-700);
      border-left: 0.175rem solid #fea834;
      // height: 4.5rem;
    }

    .sidebar-section {
      flex-grow: 1; /* Adjust the flex-grow value for responsiveness */
    }
  }
}

