// .progress-bar {
//     width: 28.75rem;
//     height: 1rem;
//     background-color:var(--Colours-Neutral-colours-Gray-100) !important ;
//     border-radius: 6.25rem;
  
  
//   .progress-bar-inner {
//     height: 100%;
//     width: 0;
//     transition: width 0.3s ease-in-out;
//      border-radius: 6.25rem;
//     border: 1px solid var(--Colours-System-colours-Warning-500);
//   }
//   @media (min-width: 375px) and (max-width: 667px) {
//     .progress-bar {
//       width: 20.75rem !important;
//     }
    
//   }
// }
.progress-bar {
  width: 28.75rem;
  height: 1rem;
  background-color: var(--Colours-Neutral-colours-Gray-100) !important;
  border-radius: 6.25rem;
  margin-right: 1rem;

  .progress-bar-inner {
    height: 100%;
    width: 0;
    transition: width 0.3s ease-in-out;
    border-radius: 6.25rem;
    border: 1px solid var(--Colours-System-colours-Warning-500);
  }

  @media (min-width: 375px) and (max-width: 667px) {
    width: 5.75rem; 
    height: 0.7rem;
    margin-left: 0.2rem !important;/* Move the width here without !important */
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 10rem;
    height: 0.8rem;
  }
}
