#CreateUser {
    .PhoneInputInput {
        padding: 10px 10px;
        position: relative;
        bottom: 1em;
        border: 1px solid var(--bs-border-color);
        outline: none;
        font-size: 0.875rem !important;
    font-family: "Medium-Regular" !important;
      }
      .PhoneInputCountry {
        bottom: 1em;
      }
      .has-error {
        .PhoneInputInput{
          background-color: var(--Colours-System-colours-Alert-50) !important;
          border: 1px solid var(--Colours-System-colours-Alert-500);
          color: var(--Colours-System-colours-Alert-500) !important;
        }
    
        .PhoneInputInput::placeholder {
          color: var(--Colours-System-colours-Alert-500) !important;
        }
      }
}


