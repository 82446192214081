.centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
   //  background-color: var(--Colours-Primary-colour-Blue-10);
     /* Optional: Set the height to 100% of the viewport height */
     h1{
        font-size: 5rem;
     }
     .secondery-btn{
      font-size: 1.2rem;
     
     }
  }
  