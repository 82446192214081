#solutions {
  .solution-box {
    background: var(--box-background-color);
    margin-bottom: 1.5rem;
    box-shadow: var(--box-shadow-color);
    overflow: hidden;

    border-radius: var(--box-border-radius);
    .solution-name {
      font-size: 1.5rem;
      font-family: "MediumHeading";
      font-weight: 600;
      color: var(--Colours-Typography-colours-Default---800);
      width: 70%;
      word-wrap: break-word;
      text-align: start;
    }
    p {
      font-weight: 500;
      font-size: 0.875rem;
      font-family: "Medium";
      margin-bottom: 0 !important;
      color: var(--Colours-Primary-colour-Blue-500) !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .solution-box:hover {
    box-shadow: var(--box-hover-color);
    border-radius: var(--box-border-radius);
  }
  @media (min-width: 375px) and (max-width: 667px) {
    .solution-box .solution-name {
      font-size: 0.9rem !important;
      font-family: "MediumHeading";
    }
    .solution-box p {
      font-size: 0.75rem !important;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .solution-box .solution-name {
      font-size: 0.9rem !important;
      font-family: "MediumHeading";
    }
    .solution-box p {
      font-size: 0.75rem !important;
    }
  }
}
