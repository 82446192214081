// .custom-toast{
//   position: fixed;
//   top: 6rem;
//   right: 1rem;
//   padding: 0.75rem;
//   border-radius: var(--box-border-radius);
//   transition: opacity 0.3s ease-in-out;
//   z-index: 1000;
//   font-size: 1rem;PP
// }
.custom-toast {
  position: fixed;
  top: 5rem;
  left: 50%; /* Adjusted to left */
  transform: translateX(-50%); /* Added */
  padding: 0.75rem;
  border-radius: var(--box-border-radius);
  transition: opacity 0.3s ease-in-out;
  z-index: 1500;
  font-size: 1rem;
}


.Success {
  background: var(--Colours-System-colours-Success-10);
  color: var(-Colours-System-colours-Success-700);
  border: 1px solid var(--Colours-System-colours-Success-700);
  padding: 0.75rem;
 /* Green color for success */
}
.Unauthorized{
  background: var(--Colours-System-colours-Alert-10);
  color: var(--Colours-System-colours-Alert-700);
  border:1px solid var(--Colours-System-colours-Alert-700);
  padding: 0.75rem;
}
.Failure{
  background: var(--Colours-System-colours-Alert-10);
  color: var(--Colours-System-colours-Alert-700);
  border:1px solid var(--Colours-System-colours-Alert-700);
  padding: 0.75rem;
}
.Alert{
  background:var(--Colours-Primary-colour-Blue-10);
  color: var(--Colours-Primary-colour-Blue-700);
  border: 1px solid var(--Colours-Primary-colour-Blue-700);
  padding: 0.75rem;
}
